<div class="products-section">
  <div class="container text-left">
    <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">Nos Produits</h2>
    <div class="row">
      <div *ngFor="let category of categories" class="col-md-3 col-sm-12">
        <div class="product-item">
          <img [src]="category.url" [alt]="category.name"  (click)="getToCategory(category.id)" />
          <div class="see-more" (click)="getToCategory(category.id)">Voir Plus</div>
        </div>
      </div>
    </div>
  </div>
</div>
