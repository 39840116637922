<footer
  class="footer-section"
>
  <div class="container">
    <div class="footer-widgets">
      <div class="row">
        <div class="col-md-5">
          <div class="foo-block">
            <img
              src="./../../assets/logo_gold.png"
              alt="Touda Fournitures Logo"
              style="width:150px;"
            />
            <p style="font-size: 16px; margin-top: 20px">
              <strong>Touda Fournitures</strong><br />
              Zone Industrielle Al Majd, Rue Arriad, n° 667, Tanger 90000, Maroc
            </p>
            <p style="margin-top: 10px">
              toudafournitures@gmail.com | +212 6 60 72 43 14
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="foo-block">
            <h4>Liens Rapides</h4>
            <p routerLink="/home" class="foot-link">Accueil</p>
            <p routerLink="/about" class="foot-link">À Propos</p>
            <p routerLink="/products" class="foot-link">Nos Produits</p>
            <p routerLink="/contact" class="foot-link">Contact</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="foo-block text-center" style="padding: 50px;">
            <a href="https://www.google.com/maps/place/35.7402354,-5.7998418" target="_blank" class="header-icon">
              <i class="fas fa-directions"></i> 
            </a>
            <a
              href="https://www.instagram.com/touda.fournitures/"
              target="_blank"
              class="header-icon"
            >
              <i class="fab fa-instagram fa-lg"></i>
            </a>
            <a
              href="https://www.instagram.com/touda.fournitures/"
              target="_blank"
              class="header-icon"
            >
              <i class="fab fa-facebook fa-lg"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="footer-copy">
        <span class="footer-year">2024</span>
        © Touda Fournitures
      </div>
      <ul class="footer-social"></ul>
    </div>
  </div>
</footer>
