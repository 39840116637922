<div class="location-contact-section">
  <div class="container" style="margin-bottom: 105px;">
    <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">À propos de Touda Fournitures</h2>
    <div class="row mt-4">
      <div class="col-md-6 text-left">
        <img
        src="./../../assets/toudafournitures1.jpg"
        alt="Importation Internationale"
      />
      </div>
      <div class="col-md-6 mt-4">
        <p style="text-align: right;">
          منذ عام 2005، تختص شركة تودى للمستلزمات في توريد منتجات الخياطة
          والمنسوجات بالجملة، مع التزام تام بالجودة. نقدم تشكيلة واسعة من
          المواد مثل الدانتيل، اللاستيك، وخيوط الخياطة، بأسعار تنافسية وشراكات
          مع موردين موثوقين. نحن نفخر بتقديم حلول موثوقة تلبي احتياجات عملائنا
          في قطاع النسيج والخياطة
        </p>
        <p>
          Depuis 2005, l'entreprise Touda Fournitures s'est spécialisé dans la fourniture de
          produits de confection et textiles. Basés à Tanger, nous sommes fiers
          de notre expertise et de notre engagement envers la qualité.
        </p>
        <p>
          Notre engagement envers la qualité se reflète dans chaque produit que
          nous fournissons. Que ce soit des dentelles, des guipures, des fils à
          coudre ou d'autres matériaux, nous mettons l'accent sur l'excellence.
        </p>
      </div>
    </div>
    
  </div>
</div>

