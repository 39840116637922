<div class="products-section">
    <div class="text-left" style="margin-left: 3%;margin-right: 4%;">
      <div  style="display: flex;">
        <i class="fas fa-arrow-left back-icon" (click)="goBack()"></i>
        <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px;width: 100%;">{{selectedProductName}}</h2>
      </div>
      <div class="row">
        <div *ngFor="let item of selectedCategory.items" class="col-md-3 col-sm-12">
          <div class="product-item">
            <img [src]="item.url" [alt]="item.name" />
            <div class="label">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  