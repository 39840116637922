<nav class="navbar navbar-expand-lg fixed-header">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="./../../assets/logo_blue.png" alt="toudafournitures Logo" class="logo-img" />
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/home">ACCUEIL</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/about">À PROPOS</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/products">NOS PRODUITS</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/contact">CONTACT</a>
        </li>
      </ul>
    </div>
    <div class="d-flex align-items-center icons">
      <a href="https://www.google.com/maps/place/TOUDA+FOURNITURES/@35.7402354,-5.7998418,17z/data=!3m1!4b1!4m6!3m5!1s0xd0b81857e2d69fb:0xe1e24372410e378d!8m2!3d35.7402354!4d-5.7972669!16s%2Fg%2F11vq15np5x?entry=ttu" target="_blank" class="header-icon">
        <i class="fas fa-directions"></i> 
      </a>
      <a href="https://www.instagram.com/touda.fournitures/" target="_blank" class="header-icon">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61558428187194&ref=xav_ig_profile_web" target="_blank" class="header-icon">
        <i class="fab fa-facebook"></i>
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleSideNav()">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</nav>

<div class="sidenav" [ngClass]="{ open: isSideNavOpen }">
  <a routerLink="/home" [routerLinkActive]="['active']" (click)="closeSideNav()">Accueil</a>
  <a routerLink="/about" [routerLinkActive]="['active']" (click)="closeSideNav()">À Propos</a>
  <a routerLink="/products" [routerLinkActive]="['active']" (click)="closeSideNav()">Nos Produits</a>
  <a routerLink="/contact" [routerLinkActive]="['active']" (click)="closeSideNav()">Contact</a>
</div>
