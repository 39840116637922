import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent {

  constructor(private router: Router){

  }

  categories = [
    { id: 'fil-a-coudre', name: 'Fil à coudre', url: './../../assets/fil_coudre.png' },
    { id: 'bandes-elastiques', name: 'Bandes élastiques', url: './../../assets/bandes_elastiques.png' },
    { id: 'rubans', name: 'Rubans', url: './../../assets/rubans.png' },
    { id: 'ciseaux', name: 'Ciseaux', url: './../../assets/ciseaux.png' },
    { id: 'soutien-gorge', name: 'Soutien-gorge', url: './../../assets/soutien_gorge.png' },
    { id: 'buttons', name: 'Boutons', url: './../../assets/buttons.png' },
    { id: 'biais', name: 'Biais', url: './../../assets/biais.png' },
    { id: 'fermetures', name: 'Fermetures', url: './../../assets/fermetures.png' },
    { id: 'rubans-adhesifs', name: 'Rubans adhésifs', url: './../../assets/rubans_adhesifs.png' },
    { id: 'etiquettes', name: 'Étiquettes', url: './../../assets/etiquettes.png' },
    { id: 'velcro', name: 'Velcro', url: './../../assets/velcro.png' },
    { id: 'aiguilles', name: 'Aiguilles', url: './../../assets/aiguilles.png' },
    { id: 'dentelles', name: 'Dentelles', url: './../../assets/dentelles.png' },
    { id: 'sachets', name: 'Sachets', url: './../../assets/sachets.png' },
    { id: 'guipure', name: 'Guipure', url: './../../assets/guipure.png' }
  ];

  getToCategory(id: string){
    const queryParams = {productName: id};
    this.router.navigate(['/items'],{queryParams})
  }
}
