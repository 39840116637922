import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  mobileMode: boolean =false;

  constructor( private router: Router
  ) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 900) {
      this.mobileMode = true;
    } else {
      this.mobileMode = false;
    }
  }

  goToProduct(id: string){
    const queryParams = {productName: id};
    this.router.navigate(['/items'],{queryParams}) 
  }
  
}
